import request from '@/utils/request'

// 获取事件接口
export function getEventList(data) {
  return request({
    url: '/user/admin/register/channel/getEventList',
    method: 'POST',
    data,
  })
}

// 获取渠道列表
export function getChannelList(params) {
  return request({
    url: '/user/admin/register/channel/getChannelList',
    method: 'GET',
    params,
  })
}

// 添加渠道
export function doAdd(data) {
  return request({
    url: '/user/admin/register/channel/addChannel',
    method: 'POST',
    data,
  })
}
// 编辑渠道
export function doEdit(data) {
  return request({
    url: '/user/admin/register/channel/updateChannel',
    method: 'POST',
    data,
  })
}

// 渠道导入观众模版下载
export function downTemplate(data) {
  return request({
    url: '/user/admin/hold/exhibition/download/template',
    method: 'POST',
    data,
  })
}

// 渠道导入观众接口
export function _importVisitor(
  data,
  appKey,
  channelNo,
  meetingCode,
  identityType,
  meetingType,
  isReal
) {
  return request({
    url: `/user/admin/hold/exhibition/import?appKey=${appKey}&channelNo=${channelNo}&meetingCode=${meetingCode}&identityType=${identityType}&meetingType=${meetingType}&isReal=${isReal}`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
// 渠道导入非大陆 观众接口
export function _importVisitorByAboard(
  data,
  appKey,
  channelNo,
  meetingCode,
  identityType,
  meetingType
) {
  return request({
    url: `/user/admin/hold/exhibition/abroad/import?appKey=${appKey}&channelNo=${channelNo}&meetingCode=${meetingCode}&identityType=${identityType}&meetingType=${meetingType}`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// 导入观众 获取展会信息
export function _importGetExhInfo(meetingType) {
  return request({
    url: `/user/admin/hold/exhibition/getMeetingCodeList?meetingType=${meetingType}`,
    method: 'POST',
  })
}

// 导入观众 获取展会信息
export function _exportUserInfo(params) {
  return request({
    url: `/user/admin/register/channel/export/channelData`,
    method: 'GET',
    params,
  })
}

// 获取身份类型列表
export function getIdentityList(eventTypeId) {
  return request({
    url: `/user/admin/identity/get/${eventTypeId}`,
    method: 'GET',
  })
}

// 获取导入信息失败结果列表
export function getErrorDataList(params) {
  return request({
    url: `/user/admin/hold/exhibition/import/errorDataList`,
    method: 'GET',
    params,
  })
}

// 导出失败信息表格
export function _exportErrorData(params) {
  return request({
    url: `/user/admin/hold/exhibition/export/errorDataList`,
    method: 'GET',
    params,
  })
}
// 获取嘉宾列表
export function getIdentity() {
  return request({
    url: `/user/meeting/identity/get`,
    method: 'POST',
  })
}

export function getUserRelayQuery(data) {
  return request({
    url: `/user/admin/relay/query`,
    method: 'POST',
    data,
  })
}
export function relayExport(code) {
  return request({
    url: `/user/admin/relay/export/${code}`,
    method: 'GET',
  })
}
/**
 * @description: 查询分配渠道权限列表
 * @param {*} channelId
 * @return {*}
 */
export function queryDistribution(channelId) {
  return request({
    url: `user/admin/register/channel/query/distribution/${channelId}`,
    method: 'GET',
  })
}

/**
 * @description: 分配渠道权限
 * @param {*} channelId
 * @return {*}
 */
export function channelDistribution(data) {
  return request({
    url: `/user/admin/register/channel/distribution`,
    method: 'POST',
    data,
  })
}
/**
 * @description: 删除分配渠道权限
 * @param {*} channelId
 * @return {*}
 */
export function delChannelDistribution(channelId, uid) {
  return request({
    url: `/user/admin/register/channel/del/distribution/${channelId}/${uid}`,
    method: 'POST',
  })
}

/**
 * @description: 查询分享渠道
 * @param {*} channelId
 * @return {*}
 */
export function relayQuery(params) {
  return request({
    url: `/user/admin/relay/query`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询主办渠道
 * @param {*} channelId
 * @return {*}
 */
export function channelQuery(params) {
  return request({
    url: `/user/admin/register/channel/query`,
    method: 'GET',
    params,
  })
}

export function channelInvitationList(data) {
  return request({
    url: `/user/admin/meeting/channel/invitation/list`,
    method: 'POST',
    data,
  })
}

export function channelInvitationAddOrUpdate(data) {
  return request({
    url: `/user/admin/meeting/channel/invitation/addOrUpdate`,
    method: 'POST',
    data,
  })
}

export function channelInvitationUpdateDefineUseNum(id, num) {
  return request({
    url: `/user/admin/meeting/channel/invitation/updateDefineUseNum/${id}/${num}`,
    method: 'POST',
  })
}

export function channelInvitationGetRecord(data) {
  return request({
    url: `/user/admin/meeting/channel/invitation/getRecord`,
    method: 'POST',
    data,
  })
}

export function channelInvitationUpdateStatus(id, status) {
  return request({
    url: `/user/admin/meeting/channel/invitation/updateStatus/${id}/${status}`,
    method: 'POST',
  })
}

export function getRegisterConfigList(meetingCode) {
  return request({
    url: `/user/admin/meeting/getRegisterConfigList/${meetingCode}`,
    method: 'GET',
  })
}

export function setUpRegister(data) {
  return request({
    url: `/user/admin/meeting/setUpRegister`,
    method: 'POST',
    data,
  })
}

// 非渠道用户导入相关 api

/**
 * @description: 新增渠道
 * @return {*}
 */
export function _importChannelAddChannel(data) {
  return request({
    url: `/user/admin/import/channel/addChannel`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 编辑渠道
 * @return {*}
 */
export function _importChannelUpdateChannel(data) {
  return request({
    url: `/user/admin/import/channel/updateChannel`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 获取渠道列表
 * @return {*}
 */

export function _importChannelGetChannelList(params) {
  return request({
    url: `/user/admin/import/channel/getChannelList`,
    method: 'GET',
    params,
  })
}

export function _importChannelExportChannelData(params) {
  return request({
    url: `/user/admin/import/channel/export/channelData`,
    method: 'GET',
    params,
  })
}

export function _importChannelImport(data, channelId) {
  return request({
    url: `/user/admin/import/channel/import/${channelId}`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function _importChannelImportErrorDataList(params) {
  return request({
    url: `/user/admin/import/channel/import/errorDataList`,
    method: 'GET',
    params,
  })
}

export function _importChannelExportErrorData(params) {
  return request({
    url: `/user/admin/import/channel/export/errorDataList`,
    method: 'GET',
    params,
  })
}

export function _importChannelQuery(params) {
  return request({
    url: `/user/admin/import/channel/query`,
    method: 'GET',
    params,
  })
}

export function _importChannelUpdateRemark(params) {
  return request({
    url: `/user/admin/import/channel/update/remark`,
    method: 'GET',
    params,
  })
}

/**
 * @description: WeMeet 机构分销相关接口
 * @return {*}
 */

/**
 * @description: 新增企业入驻渠道
 * @return {*}
 */
export function enterpriseAddEnterpriseChannel(data) {
  return request({
    url: `/user/admin/enterprise/marketing/add/enterpriseChannel`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 获取企业入驻渠道列表
 * @param {*} params
 * @return {*}
 */
export function getEnterpriseChannelList(params) {
  return request({
    url: `/user/admin/enterprise/marketing/queryEnterpriseChannelList`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询分配渠道列表
 * @return {*}
 */
export function getEnterpriseMarketingQueryDistribution(channelId) {
  return request({
    url: `/user/admin/enterprise/marketing/query/distribution/${channelId}`,
    method: 'GET',
  })
}

/**
 * @description: 分配企业渠道管理员
 * @return {*}
 */

export function enterpriseChannelUser(data) {
  return request({
    url: `/user/admin/enterprise/marketing/bind/enterpriseChannelUser`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 企业渠道删除绑定管理员
 * @return {*}
 */

export function delEnterpriseChannelUser(channelId, uid) {
  return request({
    url: `/user/admin/enterprise/marketing/del/distribution/${channelId}/${uid}`,
    method: 'POST',
  })
}

/**
 * @description: 编辑企业渠道名称
 * @return {*}
 */

export function updateEnterpriseChannel(id, name) {
  return request({
    url: `/user/admin/enterprise/marketing/update/enterpriseChannel/${id}/${name}`,
    method: 'POST',
  })
}

/**
 * @description: 查询企业渠道使用明细
 * @return {*}
 */

export function queryEnterpriseChannelDetailList(params) {
  return request({
    url: `/user/admin/enterprise/marketing/queryEnterpriseChannelDetailList`,
    method: 'GET',
    params,
  })
}
